.searching {
    /* display: none; */
    overflow: hidden;
    display: none;
}


.banner-searching {
    margin-top: 75px;
    height: 90px;
    background: none;
    background-color: rgb(10, 1, 43);
    background-color: #6C24C9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}

@media only screen and (max-width: 768px) {
    .banner-searching {
        height: 65px;
    }
}
:root {
    scroll-behavior: smooth;
    transition: 0.5s;
}

body {
    transition: 0.2s;
}

input {
    outline: none;
}

p,
h2,
button,
div,
h3,
a {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
}

.join-volunteer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    color: rgb(221, 218, 218);
    font-size: 18px;
}



.jv-banner {
    background-color: #6C24C9;
    background-image: url("../assets/images/hero1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
    height: 50vh;
    gap: 15px;
    margin-top: 65px;
    transition: 0.4s;
   
    /* margin-bottom: 100px; */
}

.team-banner {
    height: 35vh;
    font-size: 10px;
}

.jv-banner p {
    font-size: 23px;
    max-width: 80vw;
}
.jv-banner h2{
    max-width: 450px;
    font-size: 58px;
    color: white;
    max-width: 550px;
}

h2 {
   color: white;
}



.yellow-text {
    color: #F0B12E;
}

.yellow-bar {
    height: 6px;
    width: 150px;
    background-color: #F0B12E;
    border-radius: 10px;
    margin-bottom: 10px
}

.jv-card {
    background-image: url("../assets/images/Property\ 1=Variant4\(2\).png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    height: 350px;
    background-color: #6C24C9;
    gap: 30px;
    padding: 10px 40px;
    font-weight: 400;
    width: 100%;
}

.jv-card p {
    max-width: 900px;
    font-size: 24px;
    font-weight: lighter;
}


.card p {
    width: 70%;
}

.btn-join {
    color: #6C24C9;
    background-color: white;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    width: 150px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    text-align: center;
}

.btn-join:hover {
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.members {
    color: black;
    text-align: center;
    display: flex;
    gap: 100px;
}

.jv-bar {
    background: rgb(108, 36, 201);
    background: linear-gradient(90deg, rgba(108, 36, 201, 1) 32%, rgba(240, 177, 46, 1) 79%);
    width: 130px;
    height: 6px;
    border-radius: 10px;
}

.head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 50px;
}



.head h3 {
    font-size: 40px;
}

.list {
    display: grid;
    width: 1000px;
    max-width: 100vw;
    grid-template-columns: auto auto auto auto;
    gap: 50px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.form-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 10px;
    width: 800px;
    max-width: 90vw;
}


.member-card {
    gap: 5px;
    text-align: center;
    /* background-color: red; */
}

.member-card h2 {
    color: black;
    font-size: 18px;
}

.member-card p {
    color: rgb(0, 0, 0);
    width: 200px;
    font-size: 15px;
    padding: 1px;
    border-radius: 5px;
}

.member-image {
    width: 200px;
    width: 130px;
    height: 190px;

    width: 200px;
    height: 250px;
    /* transform: scale(1.3); */
    border: 3px solid white;


    object-fit: contain;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.2s;
    cursor: pointer;
    margin-bottom: 5px;
}

.member-image:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.member-link {
    color: black
}

.member-links {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


/* utility classes */
.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


@media only screen and (max-width: 768px) {
    .join-volunteer {
        gap: 0;
    }

    h2 {
        font-size: 30px;
    }

    p {
        font-size: 16px;
    }

    .jv-card {
        width: 100vw;
        height: auto;
        padding: 40px 20px;
    }

    .jv-banner {
        margin-bottom: 20px;
        /* padding-top: 20px;
        padding-bottom: 20px; */
        height: 100vh;
        transition: 0.4s;
        /* background-color: blue; */
    }

    .jv-banner h2 {
        width: 400px;
        font-size: 48px;
        max-width: 90vw;
        /* background-color: red; */
    }

    .list {
        grid-template-columns: auto;
    }


    .members {
        width: 100vw;
    }

    .member-image {
        width: 270px;
        height: 250px;
        object-fit: contain;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .member-card p {
        font-size: 18px;
        width: 250px;
    }

    .btn-join {
        font-size: 16px;
        width: 120px;
        height: 40px;
    }
}

footer {
    margin: 0 !important;
}

.loader-cont {
    height: 200px;
    gap: 20px;
}

.loader-cont h3 {
    font-size: 20px;
    padding: 20px;
}

.shadow-pop-tr {
	-webkit-animation: shadow-pop-tr 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite both;
	        animation: shadow-pop-tr 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite both;
}


@-webkit-keyframes shadow-pop-tr {
    0% {
      -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
              box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
    100% {
      -webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
              box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
      -webkit-transform: translateX(-8px) translateY(8px);
              transform: translateX(-8px) translateY(8px);
    }
  }
  @keyframes shadow-pop-tr {
    0% {
      -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
              box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
    100% {
      -webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
              box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
      -webkit-transform: translateX(-8px) translateY(8px);
              transform: translateX(-8px) translateY(8px);
    }
  }
  
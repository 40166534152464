@import url('https://fonts.cdnfonts.com/css/gilroy-bold');
@import url('https://fonts.cdnfonts.com/css/gilroy-regular');
@import url('https://fonts.cdnfonts.com/css/gilroy-medium');


body {
  font-family: 'Gilroy-Bold', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #fff;
}
.black-text{
  color: #000 !important;
}
.mission2{
  display: none;
}

.link::selection {
  color: #fff;
  background: #6C24C9;
}

p::selection {
  color: #fff;
  background: #6C24C9;
}

h1::selection {
  color: #fff;
  background: #6C24C9;
}

h2::selection {
  color: #fff;
  background: #6C24C9;
}
img::selection {
  color: #fff;
  background: #6C24C9;
}
address::selection {
  color: #fff;
  background: #6C24C9;
}

h3::selection {
  color: #fff;
  background: #6C24C9;
}

span::selection {
  color: #fff;
  background: #6C24C9;
}

/* Navbar */

nav {
  background-color: #fff;
  position: fixed;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  z-index: 9999;
  top: 0;
}

.navbar {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.allPages {
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}
.allPages2 {
  max-width: 100%;
  margin: 0 auto;
  align-items: center;
}

.nav-items {
  display: flex;
}

.nav-items ul {
  display: flex;
  list-style-type: none;
  gap: 20px;
}

.link {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.btn {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-left: 100px !important;
}

.btn1 {
  background-color: #6C24C9;
  color: #fff;
  border-radius: 48px;
  text-decoration: none;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 20px;
}
.link.active {
  position: relative;
  /* overflow: hidden; */
  display: inline-block;

}
.link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 25px;
  left: 0;
  width: 100%;
  height: 6px; /* Adjust the height of the underline as needed */
  background-image: linear-gradient(91deg, #6C24C9 11.53%, #E8AF38 84.47%);
  border-radius: 10px; /* Add curved edges to the underline */
}
.btn1.active {

  /* Gradient underline effect */
  background: linear-gradient(91deg, #6C24C9 11.53%, #E8AF38 84.47%);
  /* background-position: 0% 100%;
  background-size: 100% 6px; */
  background-repeat: no-repeat;

  /* Adjust the height of the underline as needed */
  /* padding-bottom: 10px; */
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #000; 
}

/* Hero Section */

.banner {
  background-image: url("../public/img/Frame 208.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 448px;
  margin: 0 auto;
/*   max-width: 1400px; */
  color: #fff;
}

.bannerInfo {
  padding-top: 160px;
  text-align: center;
}

.intro {
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 0.6px;
}

.bannerInfo h1 {
  font-size: 58px;
  font-weight: 700;
  margin: 0;
  line-height: 70px;
}

.btn3 {
  padding: 10px 45px;
  background-color: #fff;
  color: #6C24C9;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
}

.second-section {
  padding-top: 70px;
}

.cards .search-card .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 0px !important;
}
.cards{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  /* max-width: 900px; */
  justify-content: center;
  /* margin-top: 10px; */
}


.cards .search-card {
  background-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0px !important;
  height: 295px;
  width: 440px;
  position: relative;
  border: 0px solid white;
  transition: 0.2s;
  display: inline-block;
  border: 2px solid #6c24c9;
  border-top: none;
}

.cards .search-card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: rotate(0deg);
}
.cards .search-card .details a{
  max-width: 200px;
  margin: 0 auto;
}
.cards .search-card .details{
  max-width: 200px;
}
.search-card p {
  transition: 0.2s;
}

.cards .search-card img {
  height: 100%;
  width: 100%;
  border-radius: 0px;
}


.search {
  border: 2px solid rgba(245, 245, 245, 0.589);
  border-radius: 30px;
  height: 45px;
  width: 400px;
  max-width: 80vw;
  flex-direction: row;
  gap: 10px;
  padding-left: 10px;
}

.stroke {
  background-color: #F0B12E;
  height: 7px;
  width: 150px;
  margin-top: 15px;
  flex-shrink: 0;
  max-width: 150px;
  margin: 7px auto;
  margin-bottom: 30px;
  border-radius: 12px;
}

.edo {
  color: #F0B12E;
}

.updates h3 {
  color: #000 !important;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.stroke2 {
  width: 183px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--grad, linear-gradient(91deg, #6C24C9 11.53%, #E8AF38 84.47%));
  max-width: 183px;
  margin: 0 auto;
}

footer {
  background-color: black;
  color: white;
  height: 100px !important;
  margin-top: 50px;
}

footer .allPages {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  height: 100px;
}


footer a {
  height: 20px;
}

footer a img {
  height: 22px;
}

.icon-list {
  display: flex;
  gap: 10px;
}

footer .allPages .icons {
  display: flex;
  gap: 15px;
  align-items: center;
}

.updateBanner {
  margin-top: 50px;
}

/* .updateImage {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  height: 600px;
} */

.updateImage>div {
/*   max-width: 1400px; */
  width: 100%;
  margin: 0 auto;
  border-top: 2px solid #6C24C9; /* 2px solid black border on the top */
  border-bottom: 2px solid #6C24C9; /* 2px solid black border on the bottom */
  /* height: 600px; */
  /* position: absolute; */
/*   background-image: url(../public/img/new-bg.png);
  background-repeat: no-repeat; */
}

.updateText {
  position: relative;
  color: #000;
  text-align: center !important;
  /* padding-top: 200px; */
  padding: 100px 0;
  
}

.updateText h2 {
  font-size: 48px;
  font-weight: 00;
  margin: 0;
  line-height: normal;
  text-align: center !important;
  max-width: none !important;
  margin-top: 30px;
  color: #000 !important;
}
.bold{
  font-family: "Gilroy-Bold", sans-serif;
}

.updateText p {
  font-size: 20px;
  font-weight: 200;
  word-spacing: 5px;
  font-family: "Gilroy-Regular", sans-serif;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 40px;
}

.third-section {
  padding-top: 70px;
}

.third-section h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  text-align: center;
  max-width: none;
  margin-bottom: 20px;
}

.fourth-section p {
  color: #030303;
  font-family: 'Gilroy-Medium' sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 0;
  margin-top: 10px;
  color: #fff;
}
.fourth-section .stroke {
  margin-bottom: 0;
}

.new {
  background-image: url(../public/img/about-bg.png);
  background-size: cover;
  padding: 100px 0;
  margin-top: 50px;
  text-align: center;
}
.new2 {
  background-size: cover;
  margin-top: 80px;
  text-align: center;
}

.jv-banner3 {
  background-color: #6C24C9;
  background-image: url("../public/img/abt-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
  padding: 20px 0px;
  height: auto;
  gap: 15px;
  margin-top: 65px;
  transition: 0.4s;
  /* margin-bottom: 100px; */
}
.jv-banner3 h2{
  font-family: 'Gilroy-Bold', sans-serif;
  margin-top: 30px;
  font-size: 58px;
}
.jv-banner2 {
  background-color: #6C24C9;
  background-image: url("../public/img/abt-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
  height: 25vh;
  gap: 15px;
  margin-top: 65px;
  transition: 0.4s;
  /* margin-bottom: 100px; */
}
.jv-banner2 h2{
  font-family: 'Gilroy-Bold', sans-serif;
  margin-top: 30px;
  font-size: 58px;
}

.new h3 {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.new2 h3 {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.new2 p{
  color: #000;
  text-align: left;
  font-weight: 500;
  font-size: 25px;
  font-family: 'Gilroy-Medium', sans-serif;
  line-height: 30px;
  margin-bottom: 20px;
}
.color {
  color: #E8AF38;
}
.color2{
  color: #6C24C9;
}
.space{
  margin-right: 50px;
}
.mission {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Gilroy-Medium', sans-serif;
}

.mission img {
  width: 700px;
  max-width: 700px;
  margin: 0 auto;
  position: absolute;
}

.text1 {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  align-items: center;
  width: 300px;
  height: 200px;
  position: relative;
  right: 350px;
  top: 50px;
}


.text3 {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  align-items: center;
  width: 300px;
  height: 200px;
  position: relative;
  left: 350px;
  bottom: 150px;
}

.text2 {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  align-items: center;
  width: 300px;
  height: 200px;
  position: relative;
  /* right: 400px; */
  top: 30px;
}

.text1 p {
  margin: 0;
  color: #000;
  font-size: 17px;
}


.text2 p {
  margin: 0;
  color: #000;
  font-size: 17px;
}

.numbers{
  max-width: 100px;
  margin: 0 auto;
}
.numbers img{
  width: 100%;
}

.text3 p {
  margin: 0;
  color: #000;
  font-size: 17px;
}


/* Resourses page */

.topBar {
  background-image: url(../public/img/Frame\ 25.png);
  height: 200px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.searchBox {
  padding-top: 115px;
  max-width: 488px;
  margin: 0 auto;
}

.searchBox>div {
  width: 488px;
  border-radius: 42px;
  border: 3px solid rgba(255, 255, 255, 0.63);
  background: rgba(50, 1, 114, 0.22);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}

.searchBox>div>img {
  margin-left: 30px;
}

.topBar input {
  display: flex;
  width: 388px;
  max-width: 388px;
  height: 42px;
  align-items: center;
  gap: 22px;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.63);
  font-size: 20px;
}

.topBar input::placeholder {
  font-size: 20px;
}

.fifth-section {
/*   background-image: url(../public/img/talk-bg.png);
  background-size: cover; */
  height: 270px;
  margin-top: 100px;
  text-align: center;
}

.fifth-section>div {
  padding-top: 50px;
}

.fifth-section h3 {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-family: 'Gilroy-Regular', sans-serif;
  /* padding-top: 50px; */
}

.hello {
  font-size: 40px !important;
  font-family: 'Gilroy-Medium', sans-serif !important;
  color: #000;
}

.fifth-section>div .stroke {
  margin-bottom: 15px;
  margin-top: 10px;
}

.fifth-section p {
  font-weight: 200;
  color: #000;
  max-width: 400px;
  margin: 0 auto;
  font-size: 18px;
}

/* Number icons */

.numbers img {
  /* width: 150px !important;
  height: 150px !important; */
  position: relative !important;
}

@media only screen and (max-width: 768px) {
  .numbers img {
    width: 80px !important;
    height: 80px !important;
  }
}





.sixth-section {
  background-image: url(../public/img/talk-bg.png);
  background-size: cover;
  height: 200px;
  margin-top: 100px;
}

.sixth-section h3 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  padding-top: 80px;
}

.subStack h2 {
  color: #000;
  font-size: 30px;
}

.subStack li {
  list-style-type: none;
  border-bottom: 2px solid #E8AF38;
  margin-bottom: 20px;
}

.subStack li a {
  font-size: 30px !important;
}

.subStack li p {
  font-size: 15px;
}

.icons2 {
  display: flex;
  gap: 10px;
  max-width: 130px;
  margin: 0 auto;
  margin-top: 20px;
}
.partyPage {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
}
.partyPage iframe{
  width: 100%;
}
@media screen and (max-width: 2680px) {
    .navbar {
        display: flex;
        max-width: 1400px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .banner {
        height: 448px;
    }

    .btn {
        margin-left: 300px;
    }

    .bannerInfo h1 {
        /* font-size: 48px; */
        line-height: 70px;
    }

    .allPages {
        max-width: 1400px !important;
        margin: 0 auto;
        align-items: center;
    }

    .updateText h2 {
        font-size: 48px !important;
        font-weight: 700;
    }

    .new2 p br {
        display: none;
    }

    footer {
        max-width: 100%;
        background-color: #000 !important;
        display: flex;
        align-items: center;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* .text1 {
        top: 30px;
    }

    .text3 {
        bottom: 130px
    } */
}

@media screen and (max-width: 1440px) {
    .navbar {
        display: flex;
        max-width: 1200px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .banner {
        height: 448px;
    }

    .btn {
        margin-left: 250px;
    }

    .bannerInfo h1 {
        /* font-size: 48px; */
        line-height: 70px;
    }

    .allPages {
        max-width: 1200px !important;
        margin: 0 auto;
        align-items: center;
    }

    .updateText h2 {
        font-size: 48px !important;
        font-weight: 700;
    }

    .new2 p br {
        display: none;
    }

    footer {
        max-width: 100%;
        background-color: #000 !important;
        display: flex;
        align-items: center;
    }

    footer .allPages {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 700px !important;
    }

    /* .text1 {
        top: 30px;
    }

    .text3 {
        bottom: 130px
    } */
}

@media screen and (max-width: 1200px) {
    .navbar {
        display: flex;
        max-width: 1000px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .banner {
        height: 500px;
    }

    .btn {
        margin-left: 150px;
    }

    .bannerInfo h1 {
        /* font-size: 48px; */
        line-height: 70px;
    }

    .allPages {
        max-width: 1000px !important;
        margin: 0 auto;
        align-items: center;
    }

    .updateText h2 {
        font-size: 48px !important;
        font-weight: 700;
    }

    .new2 p br {
        display: none;
    }

    footer {
        max-width: 1200px;
        background-color: #000 !important;
        display: flex;
        align-items: center;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 400px !important;
    }

    .text1 {
        top: 30px;
    }

    .text3 {
        bottom: 130px
    }
}

@media screen and (max-width: 1024px) {
    .navbar {
        display: flex;
        max-width: 900px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .allPages {
        max-width: 900px !important;
        margin: 0 auto;
        align-items: center;
    }

    .btn {
        margin-left: 100px;
    }

    footer {
        max-width: 1024px;
        display: flex;
        align-items: center;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 300px !important;
    }

    .updateText {
        padding-top: 100px;
    }

    .mission>img {
        width: 600px;
        max-width: 600px;
        margin: 0 auto;
        position: absolute;
    }

    .text1 {
        top: 50px;
    }

    .text3 {
        bottom: 150px;
    }
}

@media screen and (max-width: 900px) {
    .navbar {
        display: flex;
        max-width: 700px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .allPages {
        max-width: 700px !important;
        margin: 0 auto;
        align-items: center;
    }

    .btn {
        margin-left: 0px;
    }

    footer {
        max-width: 900px;
        background-color: #F0B12E;
        /* margin-top: 70px; */
        display: flex;
        align-items: center;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 100px !important;
    }

    /* .updateImage {
        height: auto;
    } */

    .third-section {
        padding-top: 80px;
    }




    /* .nav-items{
        display:block;
      } */
    .nav-items ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        gap: 0px;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .hamburger {
        display: block !important;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-items {
        position: fixed;
        right: -100%;
        top: 80px;
        height: 100vh;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 9rem;
        padding-top: 2rem;
        width: -50%;
        text-align: center;
        transition: 0.5s;
        background-color: #F0B12E;
        align-items: center;
    }

    .nav-items.active {
        right: 0;
    }

    .nav-items li {
        padding: 10px 0;
    }

    .text1 {
        top: 50px;
        right: 250px;
    }

    .text3 {
        bottom: 150px;
        left: 250px;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        display: flex;
        max-width: 600px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .logo2 {
        width: 150px;
    }

    .subStack li {
        font-size: 30px !important;
    }

    .allPages {
        max-width: 600px !important;
        margin: 0 auto;
        align-items: center;
    }

    br {
        display: none !important;
    }

    footer {
        max-width: 768px;
        /* height: 70px; */
        background-color: #000 !important;
        /* margin-top: 70px; */
        display: flex;
        align-items: center;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px !important;
    }

    small {
        font-size: 18px !important;
    }

    .allPages a {
        font-size: 18px;
    }

    .btn3 {
        margin-top: 50px !important;
    }


    .hamburger {
        display: block !important;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-items {
        position: fixed;
        left: -100%;
        top: 80px;
        height: 100vh;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 9rem;
        padding-top: 2rem;
        width: 50%;
        text-align: center;
        transition: 0.5s;
        background-color: #F0B12E;
    }

    .nav-items.active {
        left: 0;
    }

    .nav-items li {
        padding: 10px 0;
    }

    /* .updateImage {
        height: auto;
        padding: 30px 0;
    } */

    .third-section {
        padding-top: 80px;
    }

    .updateText h2 {
        font-size: 30px !important;
    }

    .new p {
        max-width: 600px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 40px !important;
    }

    .new p br {
        display: none;
    }
    .intro {
        margin-bottom: 50px;
    }
    .search-card{
        height: 200px !important;
    }

    .mission img {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        position: absolute;
    }
    .missionText br{
        display: block !important;
    }
    .text1 {
        right: 200px;
        top: 80px;
    }

    .text1 h1 {
        font-size: 50px;
    }

    .text1 p {
        font-size: 12px;
    }

    .text3 {
        left: 200px;
        bottom: 110px;
    }
    .text2{
        top: 0px;
    }

    .text3 h1 {
        font-size: 50px;
    }

    .text3 p {
        font-size: 12px;
    }

    .text2 h1 {
        font-size: 50px;
    }

    .text2 p {
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {
    .navbar {
        display: flex;
        max-width: 500px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }
    .gallery{
        padding-top: 150px;
    }

    .logo2 {
        width: 150px;
    }

    .icons p {
        font-size: 10px;
    }

    .allPages {
        max-width: 500px !important;
        margin: 0 auto;
        align-items: center;
    }

    .btn Link {
        font-size: 12px !important;
    }

    br {
        display: none !important;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px !important;
    }

    .allPages a {
        font-size: 18px;
    }





    .hamburger {
        display: block !important;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-items {
        position: fixed;
        left: -100%;
        top: 80px;
        height: 100vh;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 9rem;
        padding-top: 2rem;
        width: 50%;
        text-align: center;
        transition: 0.5s;
        background-color: #F0B12E;
    }

    .nav-items.active {
        left: 0;
    }

    .nav-items li {
        padding: 10px 0;
    }

    .updateText {
        padding: 150px 0 !important;
    }

    .second-section {
        padding: 0;
    }

    .third-section {
        padding-top: 80px;
    }

    .updateText {
        padding: 0;
        padding-top: 30px;
    }

    .updateText h2 {
        font-size: 30px;
    }

    .new p {
        max-width: 400px;
        margin: 0 auto !important;
        margin-top: 20px !important;
        margin-bottom: 40px !important;
    }

    .new p br {
        display: none;
    }

    .intro {
        margin-bottom: 50px;
    }
    .mission{
/*         margin-top: 50px; */
        display: none;
    }
    .mission2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Gilroy-Medium,sans-serif;
  justify-content: center;
}
    .missionText p{
        font-size: 16px !important;
    }
    body{
        overflow-x: hidden
    }
    .mission2 > img{
            overflow-x: hidden;
            max-width: 600px !important;
    }
    .mission img {
        width: 400px;
        max-width: 350px !important;
        margin: 0 auto;
        position: absolute;
    }

    .text1 {
        right: 0px !important;
        bottom: 70px;
        top: 200px;
        position: unset;
    }

    .text1 h1 {
        font-size: 50px;
    }

    .text1 p {
        font-size: 12px;
    }

    .text3 {
        left: 0px !important;
        top: 400px;
        position: unset;
    }

    .text3 h1 {
        font-size: 50px;
    }

    .text3 p {
        font-size: 12px;
    }
    .text2{
        position: unset;
    }
    .text2 h1 {
        font-size: 50px;
    }

    .text2 p {
        font-size: 12px;
    }

    .fifth-section {
        height: auto;
        padding: 20px 0;
        margin-top: 200px;
    }
    .fifth-section > div{
        padding-top: 20px;
    }
    footer a img {
        height: 22px;
    }
}

@media screen and (max-width: 425px) {
    .navbar {
        display: flex;
        max-width: 300px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .logo img {
        width: 150px !important;
    }
    .new2 p{
        font-size: 15px;
    }
    .allPages {
        max-width: 300px !important;
        margin: 0 auto;
        align-items: center;
    }

    .bannerInfo {
        padding-top: 200px;
    }

    .bannerInfo h1 {
        font-size: 50px;
        font-weight: 700;
        margin: 0;
        line-height: 60px !important;
    }

    .btn Link {
        font-size: 12px !important;
    }

    footer .allPages {
        display: flex;
        width: 100vw;
        justify-content: space-between !important;
    }

    .icons {
        display: flex;
        flex-direction: column;
    }


    footer {
        height: auto;
        padding: 10px 0;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .allPages>img {
        width: 100px;
    }

    .icons {
        flex-wrap: wrap;
    }

    /* .allPages a {
        font-size: 10px !important;
    } */



    .hamburger {
        display: block !important;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-items {
        position: fixed;
        left: -100%;
        top: 80px;
        height: 100vh;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 9rem;
        padding-top: 2rem;
        width: 50%;
        text-align: center;
        transition: 0.5s;
        background-color: #F0B12E;
    }

    .nav-items.active {
        left: 0;
    }

    .nav-items li {
        padding: 10px 0;
    }


    .second-section {
        padding: 0;
    }

    .third-section {
        padding-top: 80px;
    }

    .updateText {
        padding: 0;
        padding-top: 30px;
    }

    .updateText h2 {
        font-size: 30px;
    }

    .new p {
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
    }

    .new p br {
        display: none;
    }

    .intro {
        margin-bottom: 20px;
    }

    .mission img {
        width: 400px;
        max-width: 350px;
        margin: 0 auto;
        position: absolute;
    }

    .text1 {
        right: 0px;
        bottom: 70px;
    }

    .text1 h1 {
        font-size: 50px;
    }

    .text1 p {
        font-size: 12px;
    }

    .text3 {
        left: 0px;
    }
    .hello{
        font-size: 20px !important;
    }
    .text3 h1 {
        font-size: 50px;
    }

    .text3 p {
        font-size: 12px;
    }

    .text2 h1 {
        font-size: 50px;
    }

    .text2 p {
        font-size: 12px;
    }

    .fifth-section {
        height: auto;
        padding: 20px 0;
    }

    .fifth-section h3 {
        padding-top: 30px;
    }

    .fifth-section p {
        max-width: 300px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 320px) {
    .navbar {
        display: flex;
        max-width: 280px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .logo img {
        width: 100px !important;
    }

    .allPages {
        max-width: 280px !important;
        margin: 0 auto;
        align-items: center;
    }

    .bannerInfo {
        padding-top: 150px;
    }

    .bannerInfo h1 {
        line-height: normal;
    }

    footer {
        height: auto;
        padding: 10px 0;
    }

    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .allPages>img {
        width: 100px;
    }

    .icons {
        flex-wrap: wrap;
    }


    .allPages a {
        font-size: 10px !important;
    }



    .hamburger {
        display: block !important;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-items {
        position: fixed;
        left: -100%;
        top: 80px;
        height: 100vh;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 9rem;
        padding-top: 2rem;
        width: 50%;
        text-align: center;
        transition: 0.5s;
        background-color: #F0B12E;
    }

    .nav-items.active {
        left: 0;
    }

    .nav-items li {
        padding: 10px 0;
    }

    .updateText {
        padding: 0;
        padding-top: 30px;
    }

    .updateText h2 {
        font-size: 30px;
    }

    /* .updateImage {
        padding: 80px 0;
        height: 500px;
    }

    .updateImage img {
        height: 50%;
        width: auto;
        flex-shrink: 0;
        
    } */
    .mission img {
        width: auto;
        max-width: 300px !important;
    }

    .new p {
        max-width: 300px;
    }
}

@media screen and (max-width: 280px) {
    .banner {
        height: auto !important;
        padding-bottom: 20px;
    }

    .logo img {
        width: 100px !important;
    }

    .bannerInfo p {
        font-size: 12px;
    }

    .navbar {
        display: flex;
        max-width: 200px !important;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .allPages {
        max-width: 200px !important;
        margin: 0 auto;
        align-items: center;
    }



    footer {
        max-width: 280px;
        height: 70px;
        background-color: #F0B12E;
        /* margin-top: 70px; */
        display: flex;
        align-items: center;

    }



    footer .allPages {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .allPages a {
        font-size: 10px !important;
    }



    .hamburger {
        display: block !important;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-items {
        position: fixed;
        left: -100%;
        top: 80px;
        height: 100vh;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 9rem;
        padding-top: 2rem;
        width: 50%;
        text-align: center;
        transition: 0.5s;
        background-color: #F0B12E;
    }

    .nav-items.active {
        left: 0;
    }

    .nav-items li {
        padding: 10px 0;
    }

    .mission img {
        max-width: 250px;
    }

    .bannerInfo h1 {
        font-size: 30px;
    }
}

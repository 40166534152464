/* Marquee.css */

@keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-1300%);
    }
  }
  
  .marquee-container {
    width: 100%;
    overflow: hidden;
  }
  
  .marquee-content {
    display: flex;
    animation: marquee 300s linear infinite; /* Adjust duration as needed */
    align-items: center;
    height: 200px;
    /* margin-top: 30px; */
    gap: 100px;
  }
  
  .image-container {
    margin-right: 50px; /* Adjust spacing between images as needed */
    width: 400px;
  }
  
  .image-container:last-child {
    margin-right: 0; /* Remove margin for the last image */
  }
  .image-container img{
    width: 150px;
  }
  
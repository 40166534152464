/* Marquee.css */

@keyframes gallery {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-1000%);
  }
}

.gallery {
  padding-top: 100px;
}

.gallery h3 {
  color: #000;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}

.gallery-container {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  border-top: 2px solid #F0B12E;
  border-bottom: 2px solid #F0B12E;
  margin-top: 50px;
}

.gallery-content {
  display: flex;
  animation: marquee 70s linear infinite;
  /* Adjust duration as needed */
  align-items: center;
  height: 300px;
}

.gallery-image-container {
  margin-right: 10px;
  /* Adjust spacing between images as needed */
  width: 200px;
  display: flex;
  background-color: rgb(163, 163, 163);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px !important;
}

.gallery-image-container:last-child {
  margin-right: 0;
  /* Remove margin for the last image */
}

.gallery-image-container img {
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}


.gallery-image-container2 {
  margin-right: 10px;
  /* Adjust spacing between images as needed */
  width: 200px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  gap: 10px;
  align-items: center;
  
}

.gallery-image-container2:last-child {
  margin-right: 0;
  /* Remove margin for the last image */
}
.gallery-image-container2 > .top-img{
  width: 200px;
  height: 170px;
  background-color: rgb(163, 163, 163);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px !important;
}
.gallery-image-container2 > .down-img{
  width: 200px;
  height: 120px;
  background-color: rgb(163, 163, 163);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px !important;
}
.gallery-image-container2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
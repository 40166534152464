.r-banner {
    gap: 70px;
}

.r-banner>div:first-child {
    gap: 20px;
}

.resources input {
    background: none;
    border: none;
    height: 100%;
    width: 100%;
    color: white;
    font-size: 18px;
}

.resource-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    gap: 20px;
    min-height: 500px;
    width: 80vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 30px;
    padding: 10px;
    padding-bottom: 30px;
    border-radius: 20px;
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 40px;
}

.search-card {
    background-color: rgb(163, 163, 163);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 40px !important;
    height: 295px;
    width: 289px;
    position: relative;
    border: 4px solid white;
    transition: 0.2s;
}

.search-card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: rotate(-4deg) scale(1.1);
}

.search-card p {
    transition: 0.2s;
}

.search-card img {
    height: 100%;
    width: 100%;
    border-radius: 40px;
}


.search {
    border: 2px solid rgba(245, 245, 245, 0.589);
    border-radius: 30px;
    height: 45px;
    width: 400px;
    max-width: 80vw;
    flex-direction: row;
    gap: 10px;
    padding-left: 10px;
}

.sorry {
    color: black;
    height: 100px;
    text-align: center;
    padding: 20px;
}
.cards .search-card .details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 14px;
    color: black !important;
    width: 256px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.search-card .details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 14px;
    color: white;
    width: 256px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.search-card h2 {
    font-size: 24px;
}

.search-card p {
    font-size: 14px;
}

@media only screen and (max-width: 768px) {
    .search-card {
        width: 80%;
    }

    .resource-list {
        width: 95vw;
    }

    .sub-resource p {
        max-width: 80vw;
    }
}
